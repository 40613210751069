const id = '6Le_0agZAAAAALis1wV-kG8ajQlonD9X05f387J2'
const url = `https://www.google.com/recaptcha/api.js?render=${ id }`

const mount = () => {
  if ( id && !exists() ) {
    var head = document.getElementsByTagName( 'head' )[ 0 ]
    var script = document.createElement( 'script' )
    script.type = 'text/javascript'
    script.src = url
    head.appendChild( script )
  }
}

const get = async () => {
  let token = null
  try {
    return await verify()
  } catch ( error ) {
  }
}

const exists = () => {
  return document.querySelectorAll( `script[src="${ url }"]` ).length > 0
}

const verify = async () => {
  return new Promise( function ( resolve, reject ) {
    if( typeof window !== 'undefined' && typeof window.grecaptcha !== 'undefined' ) {
      try {
        window.grecaptcha.ready( function() {
          window.grecaptcha.execute( id, { action: 'submit' } ).then( function( token ) {
            resolve( token )
          } )
        } )
      } catch {
        reject()
      }
    } else {
      reject()
    }
  } )
}

export default {
  mount,
  get
}
