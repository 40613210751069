import React from "react"
import Recaptcha from './recaptcha'

class Form extends React.Component {
  constructor( props ) {
    super( props )
    this.state = { status: '' }
    this.handleSubmit = this.handleSubmit.bind( this )
  }

  async handleSubmit( event ) {
    event.preventDefault()
    const form = event.target
    const formData = new FormData( form )
    const data = Object.fromEntries( formData.entries() )
    this.setState( { status: 'sending' } )
    this.disable( form )

    const token = await Recaptcha.get()
    if ( !token ) {
      this.setState( { status: 'error' } )
      return null
    }

    let response = {}
    try {
      response = await fetch( 'https://api.static.tools/forms/submissions', {
        body: JSON.stringify( { data, captcha: token, context: { form: this.props.instance } } ),
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      } )
    } catch ( error ) {
    }
    if ( response.status === 200 ) {
      this.setState( { status: 'success' } )
      if ( this.props.successUrl && typeof window !== 'undefined' ) {
        window.location.href = this.props.successUrl
      } else {
        this.reset( form )
        this.enable( form )
      }
    } else {
      this.setState( { status: 'error' } )
    }
  }

  enable ( form ) {
    const fields = form.querySelectorAll( 'input, select, textarea, button' )
    for ( let i = 0; i < fields.length; i++ ) {
      fields[ i ].disabled = false
    }
  }

  disable ( form ) {
    const fields = form.querySelectorAll( 'input, select, textarea, button' )
    for ( let i = 0; i < fields.length; i++ ) {
      fields[ i ].disabled = true
    }
  }

  reset ( form ) {
    form.reset()
  }

  componentDidMount() {
    function handleKeyUp( e ) {
      ( e.target.value.length === 0 )
        ? this.setAttribute( "class", "form-group" )
        : this.setAttribute( "class", "form-group active" )
    }

    const formField = document.querySelectorAll( ".form-group" )
    for ( let i = 0; i < formField.length; i++ ) {
      formField[ i ].addEventListener( "keyup", handleKeyUp )
    }
    Recaptcha.mount()
  }

  renderMessage() {
    let message = ''
    let style = ''
    switch( this.state.status ) {
      case 'sending':
        message = 'Sending form data<span id="wait">.</span>'
        style = { backgroundColor: '#cce5ff', color: '#004085' }
        break
      case 'success':
        message = '\u2714 Successfully sent form data! Redirecting<span id="wait">.</span>'
        style = { backgroundColor: '#d4edda', color: '#155724' }
        break
      case 'error':
        message = '\u2716 Something went wrong submitting form data! Please <a href="" style="color: #721c24 !important;font-weight: bold;text-decoration: underline !important;">refresh page and try again...</a>'
        style = { backgroundColor: '#ffbaba', color: '#721c24' }
        break
    }
    if ( message && style ) {
      const styles = { ...{ display: 'block', margin: '10px 0', padding: '10px', borderRadius: '3px' }, ...style }
      return <div style={ styles } dangerouslySetInnerHTML={{ __html: message }}></div>
    }
    return ''
  }

  render() {
    const { className, children } = this.props
    const message = this.renderMessage()
    return (
      <>
        { message }
        <form className={ className } onSubmit={ this.handleSubmit }>
        { children }
        </form>
      </>
    )
  }
}
export default Form
